<template>
  <f7-page name="home" ptr @ptr:refresh="pullToRefresh">
    <!-- Top Navbar -->
    <f7-navbar large :sliding="false">
    <!--  <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>-->
      <f7-nav-title sliding>Antennen</f7-nav-title>
      <f7-nav-title-large>Antennen</f7-nav-title-large>
    </f7-navbar>

    <!-- Page content-->
  <f7-list>
<f7-list-input
    label="Server"
    type="text"
v-model:value="server"
@change="doChangeServer()"    
  />
  </f7-list>
 <f7-block v-for="m in items">
<f7-block-title>{{m.titel}}</f7-block-title>

 <f7-block  v-for="item in m.items">
 <f7-block-header>{{item.label}}</f7-block-header>
       <f7-list media-list>
 <f7-list-item
        radio
        value="0"
		 :name="'group_'+m.id+'_'+item.id"
        v-model:title="item.titel0"
       @change="doToggle(m,item,'0')"
          v-model:subtitle="item.subTitel0"
  :checked="item.checked === '0'"
      ></f7-list-item>

 <f7-list-item
        radio
        value="1"
	 :name="'group_'+m.id+'_'+item.id"
        v-model:title="item.titel1"
       @change="doToggle(m,item,'1')"
        v-model:subtitle="item.subTitel1"
        :checked="item.checked === '1'"
      ></f7-list-item>
<!--
    <f7-list-item  v-model:footer="item.subTitel"  v-model:title="item.titel">
      <f7-toggle v-model:checked="item.checked" @toggle:change="doToggle(m,item)"></f7-toggle>
    </f7-list-item>-->
        </f7-list>
</f7-block>
</f7-block>
   
  </f7-page>
</template>
<script>
  import { f7 } from 'framework7-vue';
  export default {
methods: {
doChangeServer(){
const self = this;
self.refresh();
console.log('change: '+self.server);
f7.form.storeFormData('myServer',self.server);
},pullToRefresh(done){
const self = this;
self.refresh();
done();
}, refresh(done){
const self = this;
var uris=self.server.split(',');
self.items = [];
var promises=[];
var tmpItems=[];
for(var u in uris){
let server=uris[u];
        let dataURL = server+'?info=1';
 let uu=u;
promises.push(	new Promise(function(resolve, reject) {
 f7.request.get(dataURL, function (data) {


		var obj=JSON.parse(data);
obj.server= server;
       //console.log(uu);
tmpItems[uu]=obj;
	  //self.items.push(obj);
         
resolve();

});
       } )
);


}

 Promise.all(promises).then(function()
	{
//console.log('all Done');
//console.log(tmpItems);
self.items=tmpItems;
if(done){done();}});



}
,
doToggle(m,i,val){
  const self = this;

//console.log(i.titel+" " +i.checked);
 let dataURL = m.server+'?relais='+val+'&item='+i.id;
for(var iii in self.items){
let  fii=iii; 
let  server=m.server; 
if(self.items[fii].id===m.id){
let curitems=self.items;
self.items=[];
f7.request.get(dataURL, function (data) {
		var obj=JSON.parse(data);
		obj.server=m.server;
            
//force refresh

curitems[fii]=obj;
self.items=curitems;
 
//self.refresh();
          //  console.log( self.items[fii]);
        });
break;

}
}
}},
    mounted() { // when the Vue app is booted up, this is run automatically.
        var self = this;
var storedServer=f7.form.getFormData('myServer');
console.log(storedServer);
if(storedServer != null){
self.server= storedServer;
}
self.refresh();

    },

    data() {
      return {
      server:"/static/info.json,/static/info2.json",
	items:[]
      };
    }
  };
</script>